import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TakeshiKimura from '../images/Takeshi_Kimura.jpg'
import styled from 'styled-components'
import TeamImage from '../images/coming-soon-team.svg';


const ProfileImage = styled.img`
        max-width: 100px; 
        max-height: 100px;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 30px;
        margin-top: 30px;
        display: block;

    

`
const OurTeam = ({ location }) => {
    //const { siteURL } = useSiteMetadata();
    return (
        <Layout path={`${location.pathname}`} >
            <SEO title="Our Team" />
            <section style={{ padding: "125px 70px 15px 70px", width: "1024px", "margin": "0 auto" }}>
            <h1 style={{ fontSize: "32px", paddingTop: "10px" }}>Our Team</h1>
                <h2 style={{ "fontFamily": "Yantramanav", "fontSize": "18px", "fontWeight": "500", "color": "#121212" }}>
                    {/* Meet the team that's here to help you through every project */}
                    Our team page is coming soon!
                </h2>
                        
            </section>
            <section style={{ "height": "400px" }}>  
            {/* " style={{backgroundColor": "#fbfbfb", "height": "800px" }} */}

            <div>
                <img style={{ width: "25%", display:"block",  marginLeft: "auto", marginRight: "auto", paddingTop: "2%"}} src={TeamImage} />
             </div>
         
                {/* <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", listStyle: "none", padding: "0px", justifyItems: "center", "padding": "20px 0px 20px 0px" }}>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "20px", fontWeight: "600", margin: "0px", "textAlign": "center", "margin": "10px" }}>Takeshi Kimura</h2>
                        <p style={{ fontSize: "16px", "textAlign": "center" }} >Takeshi Kimura ESQ is the founder of both Juristerra. Mr. Kimura studied law at the prestigious University of Tokyo, graduating in 2002 and subsequently worked at Japan's number one ranked law firm, Nishimura and Asahi LLP. At Nishimura, Mr. Kimura worked on complex international issues including legal counsel at the Tokyo Stock Exchange and the Japan Financial Services Agency.</p>
                    </li>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "16px", fontWeight: "600", margin: "0px", "textAlign": "center" }}>Request project manager</h2>
                        <p style={{ fontSize: "14px", "textAlign": "center" }} >You submit a request via our website.</p>
                    </li>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "16px", fontWeight: "600", margin: "0px", "textAlign": "center" }}>Request project manager</h2>
                        <p style={{ fontSize: "14px", "textAlign": "center" }} >You submit a request via our website.</p>
                    </li>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "16px", fontWeight: "600", margin: "0px", "textAlign": "center" }}>Request project manager</h2>
                        <p style={{ fontSize: "14px", "textAlign": "center" }} >You submit a request via our website.</p>
                    </li>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "16px", fontWeight: "600", margin: "0px", "textAlign": "center" }}>Request project manager</h2>
                        <p style={{ fontSize: "14px", "textAlign": "center" }} >You submit a request via our website.</p>
                    </li>
                    <li style={{ width: "288px", "height": "369px", "background-color": "white", "padding": "20px 0px 20px 0", "backgroundClip": "content-box" }}>
                        <ProfileImage src={TakeshiKimura}></ProfileImage>
                        <h2 style={{ fontSize: "16px", fontWeight: "600", margin: "0px", "textAlign": "center" }}>Request project manager</h2>
                        <p style={{ fontSize: "14px", "textAlign": "center" }} >You submit a request via our website.</p>
                    </li>
                </ul> */}
            </section>

        </Layout >

    );
}

export default OurTeam
